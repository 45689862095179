<template>
  <div>
    <el-form :inline="true">
      <el-button
        v-p="['externaladmin:financialManagement:receivingAccountManagement:add']"
        type="primary"
        icon="el-icon-plus"
        class="add-btn"
        @click="onAdd"
        >新增收款账号</el-button
      >
      <!-- <el-button
        v-p="['externaladmin:financialManagement:receivingAccountManagement:detail']"
        size="mini"
        @click="$router.push('/financialManagement/bankCategory')"
        >银行分类管理
      </el-button> -->
    </el-form>
    <addDialog v-on="$listeners" :bankList="bankList" :sup_this="sup_this" :visible.sync="visible" :form.sync="form" />
    <div class="search-warpper formPart" v-p="['externaladmin:financialManagement:receivingAccountManagement:search']">
      <el-form :inline="true" :model="query">
        <el-form-item label="银行分类：">
          <el-select
            size="mini"
            @change="$emit(`search`)"
            style="width: 200px"
            v-model="query.bankCategoryId"
            clearable
            placeholder="请选择开户银行"
          >
            <el-option v-for="item in bankList" :key="item.id" :label="item.itemName" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="企业名称：">
          <el-input
            @change="$emit(`search`)"
            size="mini"
            clearable
            style="width: 240px"
            placeholder="请输入企业名称"
            v-model="query.companyName"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            style="height: 32px; width: 48px; padding: 0; margin: 0 0 5px 17px"
            type="primary"
            @click="$emit(`search`)"
            icon="el-icon-search"
          ></el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import addDialog from './addOrEditDialog'
export default {
  components: { addDialog },
  props: {
    query: {
      type: Object,
      default: () => ({})
    },
    bankList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      options: [],
      visible: false,
      form: {},
      sup_this: this
    }
  },
  methods: {
    onAdd() {
      this.visible = true
    }
  },
  created() {
    //请求调用会接口，将detail赋值给options
  }
}
</script>

<style lang="scss" scoped>
.add-btn {
  height: 28px;
  padding: 0 10px;
  line-height: 28px;
}
.formPart {
  margin: 20px 0;
  background-color: #f7f8fa;
  padding: 20px 20px 5px;
  .el-form-item {
    margin-right: 20px;
  }
}
</style>
