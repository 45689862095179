<template>
  <div class="dialog-warpper">
    <el-dialog
      v-if="visible"
      append-to-body
      :title="type === 'add' ? '新增收款账号' : '修改收款账号'"
      :visible.sync="visible"
      width="540px"
      :before-close="handleClose"
      class="dialog-warpper"
    >
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="120px">
        <el-form-item label="收款企业名称:" prop="accountName">
          <el-input
            maxlength="20"
            show-word-limit
            clearable
            style="width: 360px"
            placeholder="请输入账户名称"
            v-model="form.accountName"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行卡号:" prop="bankNumber">
          <el-input
            maxlength="20"
            show-word-limit
            clearable
            style="width: 360px"
            placeholder="请输入银行卡号"
            v-model="form.bankNumber"
          ></el-input>
        </el-form-item>
        <el-form-item label="开户银行:" prop="bankID">
          <el-input
            maxlength="20"
            show-word-limit
            clearable
            style="width: 360px"
            placeholder="请输入开户银行"
            v-model="form.bankID"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行分类:" prop="branchName">
          <el-select v-model="form.branchName" placeholder="请选择" style="width: 360px">
            <el-option v-for="item in bankList" :key="item.id" :label="item.itemName" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否启用:" prop="state">
          <div style="display: flex; align-items: center">
            <el-tooltip placement="top" effect="light">
              <div slot="content">设置为启用后，分销商使用银行转账充值余额时呈现的银行账号相应转变</div>
              <i class="el-icon-question margin" style="margin-right: 5px; color: blue; font-size: 16px"></i>
            </el-tooltip>
            <el-switch style="height: 40px" v-model="form.state"> </el-switch>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCanceled">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="handleConfirmed()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deepClone, getDiffData } from '@/components/avue/utils/util'
import { createAccount, updateAccount } from '@/api/accountApi'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => ({})
    },
    sup_this: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: 'add'
    },
    bankList: {
      type: Array,
      default: () => []
    },
    bankID: {
      type: Number,
      default: 1
    }
  },
  watch: {
    visible: {
      handler(val) {
        if (!val)
          this.$emit('update:form', { accountName: '', bankNumber: '', bankID: '', branchName: '', state: false })
        this.formCopy = val ? deepClone(this.form) : {}
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      rules: {
        accountName: [{ required: true, message: '账号名称必填', trigger: 'blur' }],
        bankNumber: [{ required: true, message: '银行卡号必填', trigger: 'blur' }],
        bankID: [{ required: true, message: '开户银行必填', trigger: 'blur' }],
        branchName: [{ required: true, message: '银行分类必选', trigger: 'blur' }]
      },
      loading: false,
      formCopy: {}
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false)
      this.$emit('update:form', {})
      this.$refs.ruleForm.clearValidate()
    },
    handleCanceled() {
      this.$emit('update:visible', false)
    },
    handleConfirmed() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) return
        this.loading = true
        if (!getDiffData(this.form, this.formCopy)) {
          this.$emit('update:visible', false)
          this.$listeners.refreshPage()
          this.$message.success(`成功编辑收款账号信息`)
          return
        }
        const isEnabled = this.form.state ? 1 : 0
        let data = {
          companyName: this.form.accountName,
          bankAccountNo: this.form.bankNumber,
          bankName: this.form.bankID,
          bankCategoryId: this.form.branchName,
          isEnabled
        }
        if (this.type == 'edit') data = { id: this.bankID, ...data }
        const fun = this.type == 'add' ? createAccount : updateAccount
        const message = this.type == 'add' ? '成功新增收款账号' : '成功编辑收款账号'
        try {
          const { code } = await fun(data)
          if (!$SUC(code)) {
            this.loading = false
            this.$emit('update:visible', false)
            this.$listeners.refreshPage()
            this.$message.success(message)
          }
        } catch (error) {
          this.loading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-warpper {
  ::v-deep {
    .el-dialog__title {
      font-size: 14px;
      color: #1a1a1a;
    }
    .el-dialog__header {
      height: 52px;
      line-height: 52px;
      padding: 0 0 0 24px;
      border: 1px solid #dcdee0;
    }
  }
}
</style>
