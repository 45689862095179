import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

//获取银行分类
export function getBankList(data) {
  return request({
    url: '/externaladmin/systemService/dictionaryItem/bankCategoryList',
    method: 'post',
    data
  })
}

//创建收款账号
export function createAccount(data) {
  return request({
    url: '/externaladmin/financeService/payeeBankAccount/create',
    method: 'post',
    data
  })
}

//更新收款账号信息
export function updateAccount(data) {
  return request({
    url: '/externaladmin/financeService/payeeBankAccount/update',
    method: 'post',
    data
  })
}

//更新状态
export function updateStatus(data, menuType = 'btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/financeService/payeeBankAccount/updateEnableStatus',
    method: 'post',
    data
  })
}


// 固定档位列表
export function fixedLevelList(data) {
  return request({
    url: '/externaladmin/authService/user/fixedLevelCategoryList',
    method: 'post',
    data
  })
}
// 设置固定档位
export function fixedLevelCreate(data) {
  return request({
    url: '/externaladmin/authService/user/fixedLevelCategoryCreate',
    method: 'post',
    data
  })
}
// 修改固定档位
export function fixedLevelUpdate(data) {
  return request({
    url: '/externaladmin/authService/user/fixedLevelCategoryUpdate',
    method: 'post',
    data
  })
}
// 删除固定档位
export function fixedLevelDelete(data) {
  return request({
    url: '/externaladmin/authService/user/fixedLevelCategoryDelete',
    method: 'post',
    data
  })
}
// 批量设置固定档位
export function fixedLevelBatchSet(data) {
  return request({
    url: '/externaladmin/authService/user/fixedLevelCategoryBatchSet',
    method: 'post',
    data
  })
}
